<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：发票开票</strong>
                                </el-col>
                            </el-row>
                        </div>
                        <el-row>

                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="开票日期:">
                                    <el-date-picker
                                            style=" width: 140px"
                                            v-model="formData.bill_date"
                                            :clearable="false"
                                            :disabled="formData.is_cancel"
                                            @change="isDataChanage = true"
                                            align="right"
                                            type="date"
                                            size="mini"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-tooltip class="item" effect="dark" placement="top" :open-delay = "750">
                                    <div slot="content">
                                        单号:{{formData.check ? formData.check : '自动生成'}}
                                    </div>
                                    <el-form-item label="单号:">
                                        <el-input v-model="formData.id" placeholder="自动生成"
                                                  :readonly="true"
                                                  size="mini"></el-input>
                                    </el-form-item>
                                </el-tooltip>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="记录员:">
                                    <el-input ref="registrar" v-model="formData.registrar" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('create_date')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="创建时间:">
                                    <el-input ref="create_date" v-model="formData.create_date"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('customer')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="修改人:">
                                    <el-input ref="last_name" v-model="formData.last_name"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('bill_type')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="8">
                                <el-form-item label="修改时间:">
                                    <el-input ref="last_date" v-model="formData.last_date"
                                              placeholder="自动生成" :readonly="true" size="mini"
                                              @keyup.native.enter="$_focusNext('last_name')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="6" :sm="12" :md="6">
                                <el-form-item :label="'销售开单日期:'" label-width="105px">
                                    <el-date-picker
                                            style=" margin-top: 8px; width: 240px"
                                            v-model="pickerDate"
                                            type="daterange"
                                            size="mini"
                                            :clearable="false"
                                            :picker-options="pickerOptions"
                                            @change="isDataChanage = true,searchWBInvoice()"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            align="right">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                                <el-col :lg="7" :sm="12" :md="8">
                                    <el-tooltip class="item" effect="dark" placement="bottom-end" :open-delay = "750">
                                        <div slot="content">
                                            {{formData.company_name}}
                                        </div>
                                        <el-form-item :label="'开票单位:'">
                                            <el-select ref="company_uuid" v-model="formData.company_uuid"
                                                       style="width: 100%" multiple collapse-tags
                                                       :disabled="formData.is_cancel"
                                                       default-first-option remote :remote-method="$_searchCustomerList"
                                                       clearable placeholder="可搜索查询"
                                                       filterable size="mini"
                                                       @change="isDataChanage = true,companyNameChangeEvent()"
                                                       @keyup.native.enter="$_blurNext('company_uuid'),$_focusNext('handled_by')">
                                                <el-option
                                                        v-for="item in $store.getters.getCustomerList"
                                                        :key="item.id"
                                                        :label="item.customer_name"
                                                        :value="item.company_uuid">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-tooltip>
                                </el-col>
                            <el-col :lg="3" :sm="12" :md="8">
                                <el-form-item label="经手人:">
                                    <el-select ref="handled_by" v-model="formData.handled_by" clearable
                                               placeholder="可搜索查询"
                                               size="mini"
                                               default-first-option
                                               :disabled="formData.is_cancel"
                                               :filter-method="$_handledByListFilter"
                                               @change="isDataChanage = true"
                                               @keyup.native.enter="$_blurNext('handled_by'),$_focusNext('remark')"
                                               filterable>
                                        <el-option
                                                v-for="item in $store.getters.getHandledByList"
                                                :key="item.nick_name"
                                                :label="item.nick_name"
                                                :value="item.nick_name">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="5" :sm="12" :md="5">
                                <el-form-item label="单据备注:">
                                    <el-input ref="remark" v-model="formData.remark" size="mini"
                                              :disabled="formData.is_cancel"
                                              @change="isDataChanage = true"
                                              @keyup.native.enter="$_blurNext('remark'),$_focusNext('additional_remark')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="3" :sm="12" :md="3">
                                <el-form-item label="合计:">
                                    <el-input size="mini" :readonly="true"
                                              :value="this.$XEUtils.commafy(formData.total_amount?formData.total_amount:0, {digits: 2})"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="3" :sm="12" :md="3">
                                <el-form-item label="开票金额:" label-width="100px">
                                    <vxe-input size="mini" @change="getTotalBalanceMoney()" type="float" style="width: 100px" :controls="false" v-model="formData.invoice_money"></vxe-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="3">
                                <el-form-item label="合计冲账:" label-width="100px">
                                    <el-input size="mini" :readonly="true"
                                              :value="this.$XEUtils.commafy(formData.total_balance_money ?formData.total_balance_money :0, {digits: 2})"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="8" :sm="12" :md="8">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; margin-left: 10px" type="success"
                                           @click="$router.push({path: '/invoice'})"
                                           size="mini">新建
                                </el-button>

                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-top: 8px;margin-bottom: 8px; ">返回
                                </el-button>
                                <el-button type="primary" size="mini"
                                           :disabled="this.isBlankVue(formData.id)"
                                           style="margin-top: 8px;margin-bottom: 8px; " @click="deleteInvoice()">删除
                                </el-button>
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " :disabled="formData.is_cancel"
                                           type="danger" plain @click="saveOrUpdateInvoice()"
                                           size="mini">保存
                                </el-button>
                                <el-button v-if="$_getActionKeyListBoolean(['/transferSlip'])" style=" margin-top: 8px; " type="primary" round :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           @click="$_openBill($refs.xGrid.getCurrentRecord())" size="mini">修改销售开单
                                </el-button>
                            </el-col>

                        </el-row>


                    </el-col>
                </el-row>

                <el-row>
                    <el-col :lg="24">
                            <vxe-grid
                                    border
                                    resizable
                                    show-overflow
                                    keep-source
                                    :show-footer="false"
                                    ref="xGrid"
                                    row-id="id"
                                    :footer-method="footerMethod"
                                    size="mini"
                                    height="600"
                                    highlight-hover-row
                                    highlight-current-row
                                    :print-config="{}"
                                    :data="tableDataMain"
                                    :columns="tableDataColumn"
                                    :checkbox-config="{labelField:'isOk',reserve: true,range:true}"
                                    :mouse-config="{selected: true}"
                                    :pager-config="tablePage"
                                    @page-change="handlePageChange"
                                    @checkbox-all="sumCheckBoxRows"
                                    @checkbox-change="sumCheckBoxRows"
                                    @checkbox-range-change="sumCheckBoxRows"
                                    :edit-config="{showIcon:true,trigger: 'dblclick', mode: 'cell', showStatus: true}"
                                    :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:false}">
                            </vxe-grid>


                    </el-col>
                </el-row>


            </el-form>
        </el-main>
    </div>
</template>

<script>


    export default {

        data() {
            return {
                pageType:'发票开票',
                filterData: {},
                sortData: {},
                pickerDate: [new Date(new Date().getTime() - 3600 * 1000 * 24 * 90), new Date()],
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                   },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', [new Date(), new Date()]);
                       }
                   }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', [date, date]);
                       }
                   }, {
                        text: '最近周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [date, new Date()]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }]
               },
                formData: {
                    id: '',//开发票 ID
                    total_amount: 0,//合计
                    total_balance_money : 0,//合计冲账金额
                    invoice_money: '',//开票金额
                    bill_date: new Date(),//开单日期
                    company_name : [],//开票单位
                    company_uuid : [],//companyUUID
                    handled_by: '',//经手人
                    remark: '',//备注
                    registrar: '',//记录人
                    last_name : '',//最后修改人
                    last_date: '',//最后修改日期
                    create_date: '',//创建日期
                    is_cancel: false,//作废
                    is_delete : false,//删除
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                    pageSizes: [ 500,1000,2500,5000]
               },
                checkboxRowTableData:[],
                tableDataMain: [],
                tableDataColumn: [
                    {type: 'seq', title: '序号', width: 40},
                    {
                        field:'customer', width: 122, title: '公司名称',
                   },{
                        field:'only_bill_id', width: 122, title: '单据编号',
                   },
                    {
                        field:'bill_id_type', width: 122, title: '单据类型', formatter: ({cellValue}) => {
                            if (cellValue == 'KD') {
                                return '销售开单'
                           } else {
                                return '未知异常'
                           }
                       }
                   },
                    {field:'tax_tate_warehouse_bill', width: 100, title: '开票税率'},
                    {field:'remark_warehouse_bill', width: 70, title: '订单备注'},
                    {
                        field:'bill_date_kd', width: 120, title: '开单日期', formatter: ({cellValue}) => {
                            return this.formatDate(cellValue, 'yyyy-MM-dd')
                       }
                   },
                    {
                        field:'registrar',
                        width: 70,
                        title: '记录员'
                   },
                    {
                        field:'sales_man',
                        width: 70,
                        title: '业务员'
                   },
                    {
                        field:'total_amount',
                        width: 120,
                        title: '应收金额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       }
                   },
                    {
                        field:'balance',
                        width: 120,
                        title: '余额',
                        formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue, {digits: 2})
                       }
                   },
                    {
                        type: 'checkbox',
                        width: 50,
                        title: '确认',
                   },
                    {
                        field:'subject_name_cash_data',
                        visible : false,
                        title: '科目名称'
                   },
                    {
                        field:'payend_wb', width: 80, title: '已收金额'
                   },
                ],
           }
       },
        methods: {
            //计算冲账金额
            getTotalBalanceMoney(){
                this.formData.total_balance_money = this.$XEUtils.subtract(this.formData.total_amount,this.formData.invoice_money)
           },
            //删除发票单
            deleteInvoice () {
                this.$XModal.confirm('您确定要删除该发票单全部数据?删除后，不可恢复！').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method:'post',
                            url:'/admin/invoice/deleteInvoiceById',
                            params: {
                                id:this.formData.id
                           }
                       }).then((response) =>{         //这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            if(response.status === 200 && response.data.state == 'ok'){
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                               });
                                this.$router.push('/invoice')
                           }else{
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                               });
                                return
                           }
                       }).catch((error) =>{
                            console.log(error)
                       });
                   }
               })
           },
            //保存或更新开票发票
            saveOrUpdateInvoice(){
                this.$axios({
                    method:'post',
                    url:'/admin/invoice/saveOrUpdateInvoice',
                    data: {
                        tableDataMain:this.$refs.xGrid.getCheckboxRecords(true),
                        formData: this.formData,
                   }
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200 && response.data.state == "ok"){
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                       });
                        this.$router.push({path: '/invoice', query: {id: response.data.id}})
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }

               }).catch((error) =>{
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.searchWBInvoice()
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                return count ? this.$XEUtils.round(count, 2) : 0
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (column.property == 'amount' || column.property == 'payend' || column.property == 'balance' || column.property == 'total_amount') {
                            sums.push(this.$XEUtils.commafy(this.sumNum(data, column.property), {digits: 2}))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },

            //付款单位
            companyNameChangeEvent() {
                //console.log(item)
                /*this.filterData = {
                    companyUUID : item.companyUUID
               }*/
                this.formData.company_name = [];//item.customer_name
                this.searchWBInvoice().then(()=>{
                    if (!this.isBlankVue(this.formData.id)){
                        this.$refs.xGrid.setCheckboxRow(this.checkboxRowTableData,true);
                   }
               });
           },
            async searchInvoiceById() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据处理中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                await this.$axios({
                    method: 'post',
                    url: '/admin/invoice/searchInvoiceById',
                    params: {
                        id: this.formData.id,
                   },
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.formData = response.data.formData;
                        //this.formData.company_name = JSON.parse(response.data.formData.company_name);
                        this.formData.company_uuid = JSON.parse(response.data.formData.company_uuid);
                        //console.log(response.data.formData.company_uuid)
                        this.tableDataMain = response.data.tableDataMain;
                        //this.$data._customerList = response.data.recordsCustomer;
                        this.$store.dispatch('asyncUpdateCustomerList', response.data.recordsCustomer);
                   } else {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            async searchWBInvoice(){
                await this.$axios({
                    method: 'post',
                    url: '/admin/invoice/searchWBInvoice',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        //filterData: this.filterData,
                        sortData: this.sortData,
                        isAccurate:true,
                        pickerDate: this.pickerDate,
                        formData: this.formData,

                   }
               }).then((response) => {//这里使用了ES6的语法
                    //(response)       //请求成功返回的数据
                    //console.log('response', response)
                    //console.log('response.data.tableData',response.data.tableData)
                    if (response.status == 200 && response.data.state == "ok") {
                        if (this.isBlankVue(response.data.page.list)){
                            this.tableDataMain = [];
                       }else{
                            this.tableDataMain = response.data.page.list
                       }
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                        this.checkboxRowTableData = response.data.checkboxRowTableData
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            sumCheckBoxRows(){
                this.formData.total_amount = 0;
                let checkBoxRows = this.$refs.xGrid.getCheckboxRecords(true);
                for (let i = 0; i < checkBoxRows.length; i++) {
                    let row = checkBoxRows[i];
                    this.formData.total_amount = this.$XEUtils.add(this.formData.total_amount, row.total_amount);
               }
                this.getTotalBalanceMoney();
           }
       }, computed: {
       },
        created() {
            this.loading = true
            this.formData.id = this.$route.query.id;
            if (this.isBlankVue(this.formData.id)){
                this.formData.bill_date = new Date();
                this.$_searchCustomerList('');
           }else{
                //存在单号，进行查询
                this.searchInvoiceById().then(() =>{
                    this.checkboxRowTableData = this.tableDataMain;
                    this.$refs.xGrid.setCheckboxRow(this.checkboxRowTableData,true);
               });
           }

            this.$_searchAccountList('');//经手人 全部用户
       }
   };


</script>

<style scoped>

    /deep/ table .el-input--mini .el-input__inner, table .el-input-number--mini {
        height: 28px;
        line-height: 28px;
   }

    /deep/ .el-input-group__prepend {
        border: initial;
   }


</style>
